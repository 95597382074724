<template>
  <div>
    <div class="child-form">
      <v-card elevation="3" class="px-1 py-1">
        <div class="d-flex align-items-center">
          <v-card-title class="text">
            <div>
              <strong>{{ label }}</strong>
              <v-chip :color="chipColor" v-if="items.length" label outlined small class="px-2 ml-2">
                <strong>{{ items.length ? `${items.length} ` : '' }}</strong>
                <span v-if="maxlength">&nbsp;{{ `of ${maxlength}` }}</span>
              </v-chip>
            </div>
          </v-card-title>
          <v-sheet class="text" v-if="valuePath.length > 1">
            ( {{ label }} with id {{ id }} )
          </v-sheet>
          <div class="d-flex align-items-center">
            <v-btn
              :data-cy="'add_' + name"
              class="ml-1 mt-4"
              fab
              depressed
              outlined
              x-small
              color="hqsOrange"
              @click="add"
              :disabled="addButtonDisabled"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <div class="ml-3 mt-n2">
              <DynamicInfoDialog
                :info="doc"
                :headerTitle="label"
              ></DynamicInfoDialog>
            </div>
            <v-btn
              data-cy="close"
              class="mx-2 my-2"
              fab
              depressed
              x-small
              color="darkGrey--text"
              v-if="valuePath.length > 1 && showChild"
              @click="hideChildTable"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <!-- add table item -->
        </div>
        <div v-if="minlength && items.length < minlength" class="px-4">
          <notice-alert
            data-cy="min-length-constraint-notice"
            :body="`At least ${minlength} ${label} ${minlength === 1 ? 'is' : 'are'} required`"
          />
        </div>
        <v-data-table
          data-cy="data_table"
          dense
          :no-data-text="noDataText"
          :headers="headers"
          :items="items"
          :hide-default-footer="true"
          disable-pagination
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              :data-cy="'edit_' + name"
              small
              @click="edit(item, items.indexOf(item))"
              >mdi-pencil</v-icon
            >
            <v-icon
              :data-cy="'delete_' + name"
              small
              @click="askConfirmation(items.indexOf(item))"
              >mdi-delete</v-icon
            >
          </template>

          <template
            v-for="(element, index) in childHeaders"
            v-slot:[`item.${element}`]="{ item }"
          >
            <v-icon
              data-cy="expand_table"
              v-bind:key="index"
              small
              @click="expandChildTable(item, items.indexOf(item), element)"
              >mdi-magnify-expand</v-icon
            >
          </template>
        </v-data-table>

        <NestedFormMultiple
          v-if="showChild && header"
          :data-cy="header"
          :name="header"
          :label="formattedHeader"
          :required="false"
          :minlength="minlength"
          :maxlength="maxlength"
          :valuePath="[...valuePath, index, header]"
        >
        </NestedFormMultiple>
      </v-card>

      <v-dialog persistent v-model="dialog" width="1000">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title
              class="hqsOrange--text text"
              :data-cy="name"
              :name="name"
            >
              {{ label }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" v-if="dialog">
                <div v-for="node in nodes" :key="node.name">
                  <NestedFormMultiple
                    v-if="node.type === 'multiple'"
                    :data-cy="node.name"
                    :name="node.name"
                    :label="node.label"
                    :required="node.required"
                    :minlength="node.minlength"
                    :maxlength="node.maxlength"
                    :valuePath="[...valuePath, index, node.name]"
                  >
                  </NestedFormMultiple>

                  <FormField
                    v-else
                    data-cy="node.name"
                    :valuePath="[...valuePath, index, node.name]"
                    :data="node"
                    :isNestedFormMultiple="isNestedFormMultiple"
                    v-model="localValue"
                  >
                  </FormField>
                </div>

                <!-- actions -->
                <v-btn text color="darkGrey" @click="clear">clear</v-btn>
                <v-btn
                  data-cy="apply"
                  rounded
                  :dark="valid"
                  depressed
                  color="success"
                  :disabled="!valid"
                  @click="apply"
                  >apply</v-btn
                >
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="remove"
      :index="index"
    ></ConfirmDialog>
  </div>
</template>

<script>
const DynamicInfoDialog = () => import('./DynamicInfoDialog.vue')
const ConfirmDialog = () => import('../general/ConfirmDialog.vue')
const NoticeAlert = () => import('../general/NoticeAlert.vue')
import dynamicFormsUtils from '../../store/storeUtils/dynamicFormsUtils.js'
import utils from '../../appUtils/utils.js'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'NestedFormMultiple',

  components: {
    FormField: () => import('./FormField.vue'),
    ConfirmDialog,
    DynamicInfoDialog,
    NoticeAlert,
  },

  data: () => ({
    showConfirmDialog: false,
    dialog: false,
    header: '',
    isNestedFormMultiple: true,
    valid: false,
    req: [(v) => !!v || v === 0 || 'required'],
    nodes: [],
    index: -1,
    parentId: 0,
    showTable: false,
  }),

  props: {
    label: String,
    name: String,
    doc: String,
    required: Boolean,
    valuePath: Array,
    value: [Object, String, Number, Array],
    minlength: Number,
    maxlength: Number,
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'applyMultipleItem',
      'reset',
      'prepareAddingMultipleItem',
      'removeItem',
      'prepareEditingMultipleItem',
      'wipeTemporaryNestedFormMultiple',
      'set',
      'populateTempWithValueToEdit',
      'moveTempIntoValues',
    ]),

    expandChildTable(item, index, element) {
      this.index = index
      this.parentId = item.id
      this.header = element
      this.set(['showChild', true])
      this.showTable = true
    },

    hideChildTable() {
      this.set(['showChild', false])
    },

    async edit(item, index) {
      this.index = index
      this.nodes = await dynamicFormsUtils.getChildNodes(
        cloneDeep(this.valuePath),
        this.normalizedSchema.nodes
      )
      await this.populateTempWithValueToEdit(cloneDeep(this.valuePath))
      await this.prepareEditingMultipleItem([index, cloneDeep(this.valuePath)])
      this.dialog = true
    },

    askConfirmation(index) {
      this.index = index
      this.showConfirmDialog = true
    },

    remove() {
      this.removeItem([cloneDeep(this.valuePath), this.index])
      this.showConfirmDialog = false
    },

    async add() {
      this.index = -1
      this.nodes = await dynamicFormsUtils.getChildNodes(
        cloneDeep(this.valuePath),
        this.normalizedSchema.nodes
      )
      await this.populateTempWithValueToEdit(cloneDeep(this.valuePath))
      await this.prepareAddingMultipleItem([
        cloneDeep(this.valuePath),
        cloneDeep(this.nodes),
      ])
      this.dialog = true
    },

    async apply() {
      this.dialog = false
      await this.applyMultipleItem(cloneDeep(this.valuePath))
      await this.moveTempIntoValues(cloneDeep(this.valuePath))
      this.hideChildTable()
    },

    clear() {
      this.$refs.form.reset()
    },

    close() {
      this.wipeTemporaryNestedFormMultiple(cloneDeep(this.valuePath))
      this.dialog = false
    },
  },

  computed: {
    ...mapGetters('dynamicForms', [
      'getTable',
      'getChildTableHeaders',
      'getMultipleValue',
    ]),

    ...mapState('dynamicForms', {
      normalizedSchema: (state) => state.normalizedSchema,
      showChild: (state) => state.showChild,
    }),

    id() {
      if (this.valuePath.at(-2) !== -1) {
        return this.valuePath.at(-2)
      } else {
        return this.parentId
      }
    },

    localValue: {
      get: function () {
        if (this.valuePath.length > 1) {
          return this.getMultipleValue(cloneDeep(this.valuePath))
        }
        return this.value
      },
      set: function (value) {
        if (this.isNestedFormMultiple) {
          this.setMultipleValue([cloneDeep(this.valuePath), value])
        } else {
          this.$emit('input', value)
        }
      },
    },

    childHeaders() {
      let nodes = cloneDeep(this.normalizedSchema.nodes)
      return dynamicFormsUtils.getNestedHeaders(
        cloneDeep(this.valuePath),
        nodes
      )
    },

    headers() {
      let items = this.getTable(cloneDeep(this.valuePath))
      let set = new Set()
      items.forEach((item) => {
        item['actions'] = 'actions'
        for (let f in item) {
          set.add(f)
        }
      })
      return Array.from(set).map((a) => {
        if (a === 'actions') {
          return {
            text: utils.formatLabel(a),
            value: a,
            align: 'right',
            sortable: false,
          }
        } else {
          return {
            text: utils.formatLabel(a),
            value: a,
            align: 'start',
            sortable: true,
          }
        }
      })
    },

    items() {
      return this.getTable(cloneDeep(this.valuePath))
    },

    noDataText() {
      return 'No ' + this.label + ' created yet!'
    },

    formattedHeader() {
      return utils.formatLabel(this.header)
    },

    addButtonDisabled() {
      if (!this.maxlength) {
        return false
      }

      return this.items.length >= this.maxlength
    },

    chipColor() {
      if (!this.maxlength || !this.minlength) {
        return 'darkGray'
      }

      if (this.items.length < this.minlength) {
        return 'red'
      }

      if (this.maxlength > this.items.length <= this.minlength) {
        return 'green'
      }

      return 'darkGray'
    }
  },
}
</script>

<style scoped>
.child-form {
  margin: 50px 50px 50px;
}
</style>
